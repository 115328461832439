import Swiper, { Navigation, Autoplay, Pagination } from 'swiper'
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

Swiper.use([Navigation, Autoplay, Pagination])

const swiper = new Swiper(".js-serviceSlider", {
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,
  loopPreventsSliding: true,
  // loopedSlides: 2,
  centeredSlides: true,
  slideToClickedSlide: true,
  navigation: {
    nextEl: ".service-slider__arrow_next",
    prevEl: ".service-slider__arrow_prev",
  },
  on: {
    slideChange: function (swiper) {
      swiper.el.querySelectorAll('video').forEach(elem => {
        elem.controls = false;
        elem.pause();
        elem.closest('.js-wrapVideo').querySelector('.js-iconPlay').style.display = 'flex';
      })
    },
  },
});

Fancybox.bind("[data-fancybox]", {
  
});