const closeHint = () => {
  document.querySelectorAll('.js-hintInfo').forEach(elem => {
    if (elem.classList.contains('is-open')) {
      elem.classList.remove('is-open');
    }
  })
}

document.addEventListener('click', event => {
  const eventTar = event.target;
  if (eventTar.classList.contains('js-hintClick')) {
    closeHint();
    eventTar.closest('.js-hint').querySelector('.js-hintInfo').classList.add('is-open')
  } else if (eventTar.classList.contains('js-closeHint')) {
    eventTar.closest('.js-hintInfo').classList.remove('is-open');
  } else if (!eventTar.closest('.js-hint')) {
    closeHint();
  }
})