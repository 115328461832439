import './vendor'

import './components/index'
import './components/service-slider'
import './components/tabs'
import './components/help-slider'
import './components/mob-menu'
import './components/newsPosts'
import './components/playVideo'
import './components/accordions'
import './components/ticker'
import './components/modal'
import './components/hint'
