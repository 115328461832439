if (document.querySelector('.js-tabs')) {
  const tabsSection = document.querySelector('.js-tabs');
  const arrLinkTab = document.querySelectorAll('.js-tabsLinks');
  const arrTabContent = document.querySelectorAll('.js-tabsContent');

  tabsSection.addEventListener('click', event => {
    if (event.target.classList.contains('js-tabsLinks')) {
      const idTab = event.target.dataset.tabName;
      console.log(idTab);

      arrLinkTab.forEach(elem => {
        elem.classList.remove('tabs__link_active')
      });

      arrTabContent.forEach(elem => {
        elem.classList.remove('tabs__content_active')
      });

      event.target.classList.add('tabs__link_active');
      document.querySelector(`#${idTab}`).classList.add('tabs__content_active');
    }
  })
}