import Swiper, { Navigation, Autoplay, Pagination } from 'swiper'

Swiper.use([Navigation, Autoplay, Pagination])

const swiper = new Swiper(".js-helpSlider", {
  slidesPerView: 'auto',
  spaceBetween: 1,
  simulateTouch: false,
  navigation: {
    nextEl: ".help-slider__arrow_next",
    prevEl: ".help-slider__arrow_prev",
  },
});